.info {
    padding-bottom: 20px;
    background-color: #ffffff;
}

.info__add {
    padding: 20px;
    position: relative;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    z-index: 1;
}

.info__add-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.info__add-open {
    display: inline-block;
    margin: 20px 20px 0;
    cursor: pointer;
}

.info__list-headers {
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    padding: 0 20px;
    margin-top: 20px;
}

.info__list-headers-item {
    display: flex;
    align-items: center;
    margin-right: 5px;
    padding: 10px;
    box-sizing: border-box;
    flex: 1 1 25%;
    background-color: #696969;
    color: #ffffff;
}

.info__list {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 20px;
    box-sizing: border-box;
    width: 100%;
}

.info__add-input {
    width: 100%;
    max-width: 300px;
    margin: 0 0 10px 0;
    display: block;
}

.info__add-checkbox {
    margin-right: 5px;
}

.info__add-checkbox-label {
    margin-bottom: 10px;
    display: block;
}

.info__buttons {
    display: flex;
}

.info__button {
    margin-right: 10px;
}

.info__button:last-child {
    margin-right: 0;
}

@media screen and (max-width: 800px) {
    .info__list-headers {
        display: none;
        /*display: flex;*/
        flex-direction: row;
        flex-wrap: wrap;
        margin: 5px 0;
        box-sizing: border-box;
        padding: 10px;
    }
    .info__list-headers-item {
        display: block;
        flex: 1 1 100%;
        box-sizing: border-box;
        padding: 5px 10px 6px;
        border-bottom: 1px solid #ffffff;
    }
}
