.auth {
    padding: 20px 60px 60px;
    max-width: 400px;
    background-color: #ffffff;
}

.auth__input {
    width: 100%;
    margin-top: 10px;
    position: relative;
}

.auth__input-password {
    padding-right: 40px;
}

.auth__show-password {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 20px;
    text-align: center;
}

.auth__button {
    margin-top: 10px;
    padding: 5px;
    font-size: 14px;
}

.auth__form_error .auth__input-input {
    border-color: #f86657;
    box-shadow: 0 0 5px rgba(248, 102, 87, 0.3);
}

.auth__link {
    margin-top: 10px;
    cursor: pointer;
    color: #585656;
    text-decoration: underline;
}

@media screen and (min-width: 600px) {
    .auth {
        margin: 40px auto;
        border-radius: 8px;
        box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    }
}
