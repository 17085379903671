.coords-converter {
    padding: 20px;
}

.coords-converter__form {
    margin-bottom: 20px;
}

.coords-converter__result {
    display: flex;
    align-items: center;
}

.coords-converter__result-button {
    margin-left: 10px;
}

.coords-converter__error {
    font-size: 18px;
    color: #f86657;
}

.coords-converter__result-text {
    font-size: 18px;
}
