.info-list-item {
    width: 100%;
    margin-top: 5px;
    min-height: 40px;
    padding: 5px 10px;
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
}

.info-list-item:nth-child(2n+1) {
    background-color: #f3f3f3;
}

.info-list-item__item {
    display: flex;
    align-items: center;
    margin-right: 5px;
    flex: 1 1 25%;
}

.info-list-item__item:last-child {
    margin-right: 0;
}

.info-list-item__item-text {
    outline: 0 solid transparent;
    border: 1px solid transparent;
    word-break: break-word;
    display: flex;
    align-items: center;
}

.info-list-item__item-text_disabled {
    opacity: 0.1;
}

.info-list-item__item_user {
    font-size: 12px;
    font-style: italic;
    max-width: 150px;
    word-break: break-word;
}

.info-list-item__item-buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.info-list-item__item-button {
    margin-right: 15px;
}

.info-list-item__item-button:last-child {
    margin-right: 0;
}

.info-list-item__item-user {
    font-size: 12px;
    font-style: italic;
}

.info-list-item__item-checkbox {
    margin-right: 10px;
}

.info-list-item_unsure .info-list-item__item-text {
    color: #f86657;
}

@media screen and (max-width: 800px) {
    .info-list-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 5px 0;
        box-sizing: border-box;
        padding: 10px;
    }
    .info-list-item__item {
        display: block;
        flex: 1 1 100%;
        box-sizing: border-box;
        padding-bottom: 5px;
    }
    .info-list-item__item:nth-child(4), .info-list-item__item:nth-child(5) {
        flex: 1 1 100%;
    }
    .info-list-item__item-buttons {
        justify-content: flex-start;
        padding-top: 10px;
    }

    .info-list-item__item:last-child {
        padding-bottom: 0;
    }

    .info-list-item__item-text:before {
        display: inline-block;
        margin-right: 10px;
        opacity: 0.1;
        width: 40px;
        color: #393939;
        flex-shrink: 0;
    }

    .info-list-item__item-text_correspondent:before {
        content: "Кор.: ";
    }

    .info-list-item__item-text_callsign:before {
        content: "ТЛФ: ";
    }

    .info-list-item__item-text_callsign-tlg:before {
        content: "ТЛГ: ";
    }

    .info-list-item__item-text_comment:before {
        content: "Ком.: ";
    }

    .info-list-item__item_empty {
        display: none;
    }
}
