.points {
    padding: 20px;
}

.points__form {
    margin-bottom: 10px;
}

.points__map {
    position: relative;
}

.points__color-picker {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.points__show-color {
    position: absolute;
    top: 90px;
    left: 10px;
    z-index: 1;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    cursor: pointer;
}

.points__circle-radius {
    position: absolute;
    top: 140px;
    left: 10px;
    z-index: 1;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}

.points__circle-radius-input {
    width: 100px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
}

.points__show-color-button {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #adb9c6;
    margin-left: 10px;
}
