.users-item {
    display: flex;
    align-items: center;
    height: 100%;
}

.users-item__name {
    width: 200px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-item__permissions {
    list-style: none;
    margin: 0 10px 0 0;
    padding: 0;
    display: flex;
}

.users-item__permissions-item {
    margin-right: 10px;
    cursor: pointer;
}

.users-item__permissions-item:last-child {
    margin-right: 0;
}
