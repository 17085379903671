.menu {
  display: flex;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  padding: 0 20px;
  justify-content: space-between;
  background-color: #393939;
  color: #ffffff;
  font-size: 18px;
  overflow-x: auto;
}

.menu__section {
  display: flex;
  height: 100%;
  margin-left: 20px;
}


.menu__section:first-child {
  margin-left: 0;
}

.menu__item {
  margin-left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.menu__item a {
  height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

.menu__item:first-child {
  margin-left: 0;
}

.menu__sign-out {
  cursor: pointer;
}

.menu__item a.menu__active-link {
  color: #FF8F00;
}
