.copy-to-clipboard-popup {
    position: relative;
    padding: 50px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 5px;
    width: 95vw;
    max-width: 800px;
}

.copy-to-clipboard-popup__button {
    display: block;
    margin-top: 10px;
    padding: 5px;
    font-size: 14px;
}

.copy-to-clipboard-popup__text {
    margin-bottom: 10px;
    font-size: 18px;
}

.copy-to-clipboard-popup__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.7;
}

.copy-to-clipboard-popup__close:hover {
    opacity: 1;
}
