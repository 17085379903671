.common-button {
    padding: 10px;
    box-sizing: border-box;
    min-width: 37px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
}

.common-button_default {
    color: #393939;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.common-button_green {
    color: #ffffff;
    box-shadow: 0 0 4px rgba(40, 147, 69, 1);
    background-color: rgba(40, 147, 69, 1);
}

.common-button_light-green {
    color: #393939;
    box-shadow: 0 0 4px rgba(173, 228, 197, 0.5);
    background-color: rgba(173, 228, 197, 0.5);
}

.common-button_red {
    color: #ffffff;
    box-shadow: 0 0 4px rgba(255, 3, 62, 1);
    background-color: rgba(255, 3, 62, 1);
}

.common-button_yellow {
    color: #ffffff;
    box-shadow: 0 0 4px rgba(206, 156, 9, 1);
    background-color: rgba(206, 156, 9, 1);
}

.common-button_light-blue {
    color: #ffffff;
    box-shadow: 0 0 4px rgba(36, 113, 163, 1);
    background-color: rgba(36, 113, 163, 1);
}

.common-button_disabled {
    color: #adb9c6;
    opacity: 0.5;
    cursor: auto;
}
