.map-form__title {
    font-size: 18px;
    margin-bottom: 10px;
}

.map-form__input {
    font-size: 18px;
    margin-bottom: 10px;
    max-width: 600px;
    display: block;
}

.map-form__button {
    display: inline-block;
    margin-right: 10px;
}

.map-form__button:last-child {
    margin-right: 0;
}

.map-form__checkbox {
    margin-right: 10px;
}
