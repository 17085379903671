.common-input {
    outline: none;
    font-weight: 400;
    padding: 5px 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: #393939;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e6e8ea;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
}

.common-input::placeholder {
    opacity: 0.5;
}
